<script lang="ts" setup>
  import type CDropdownMenu from '../CDropdownMenu/CDropdownMenu.vue'

  withDefaults(
    defineProps<{
      hideChevron?: boolean
      hideUnderline?: boolean
    }>(),
    {
      hideChevron: false,
      hideUnderline: false
    }
  )

  const emit = defineEmits<{
    (event: 'open'): void
    (event: 'close'): void
    (event: 'blur'): void
  }>()

  const el = ref<HTMLDivElement | null>(null)
  const isOpen = ref<boolean>(false)

  function open() {
    isOpen.value = true
  }

  function close() {
    isOpen.value = false
  }

  function blur() {
    emit('blur')
  }

  watchEffect(() => {
    if (isOpen.value) {
      emit('open')
    } else {
      emit('close')
    }
  })

  defineExpose({
    open,
    close,
    el
  })
</script>

<template>
  <div
    ref="el"
    class="c-dropdown"
    @blur="blur"
    @click="isOpen = !isOpen"
    @focusin="open"
    @mouseleave="close"
    @mouseover="open"
    @keydown.enter.prevent="isOpen = !isOpen"
    @keydown.tab.exact="close"
    @keydown.shift.tab.exact="close"
  >
    <div
      :aria-expanded="isOpen"
      :class="[
        'dropdown-trigger',
        {
          'dropdown-trigger--open': isOpen,
          'dropdown-trigger--animate': isOpen && !hideUnderline
        }
      ]"
      tabindex="0"
    >
      <slot name="trigger" />

      <icon-ionic-chevron-down v-if="!hideChevron" class="chevron" height="24" width="24" />
    </div>

    <CDropdownMenu :is-open="isOpen">
      <slot name="list" />
    </CDropdownMenu>
  </div>
</template>

<style lang="scss" scoped>
  .c-dropdown {
    position: relative;

    .dropdown-trigger {
      display: flex;
      align-items: center;
      padding: 8px 4px;

      border-bottom: 2px solid transparent;
      margin-bottom: -2px;

      cursor: pointer;
    }

    .chevron {
      margin-left: 4px;

      -moz-transition: transform 250ms;
      -webkit-transition: transform 250ms;
      transition: transform 250ms;
    }

    // Animations
    .dropdown-trigger[aria-expanded='true'] {
      .chevron {
        transform: rotate(180deg);
      }
    }

    .dropdown-trigger[aria-expanded='false'] {
      .chevron {
        transform: rotate(0deg);
      }
    }

    @keyframes fade-dropdown-trigger {
      0% {
        border-bottom: 2px solid transparent;
      }
      100% {
        border-bottom: 2px solid var(--color-base-red);
      }
    }

    .dropdown-trigger--animate {
      animation: fade-dropdown-trigger 300ms ease-out forwards;
    }
  }
</style>
