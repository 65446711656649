<script lang="ts" setup>
  defineProps<{
    isOpen: boolean
  }>()

  const dropdownMenuListRef = ref<HTMLUListElement | null>(null)

  defineExpose({
    dropdownMenuListRef
  })
</script>

<template>
  <div v-if="isOpen" :class="['c-dropdown-menu', { 'dropdown-menu--open': isOpen }]">
    <ul :ref="(el) => (dropdownMenuListRef = el as HTMLUListElement)" class="dropdown-menu-list" role="menu">
      <slot name="default" />
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .c-dropdown-menu {
    z-index: 100;
    position: absolute;
    top: 100%;
    padding-top: 8px;

    .dropdown-menu-list {
      background-color: var(--color-base-green);
      border-radius: 4px;

      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  @keyframes fade-dropdown-menu {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .dropdown-menu--open {
    animation: fade-dropdown-menu 300ms ease-out forwards;
  }
</style>
